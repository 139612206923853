.checkboxes-container {
  margin: auto;
  display: inline-block;
  padding-bottom: 1%;
  border-bottom: 1px solid #dee2e6;
}

.checkboxes-row {
  padding: 0.5% 3%;
  box-sizing: border-box;
}

.control-margin {
  margin-right: 5%;
}

.label {
  font-size: 1.2em;
}