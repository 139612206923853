$light-grey: #f9f9f9;
$grey: #ddd;
$red: red;

.formContainer {
  padding: 30px;
  h2 {
    text-align: center;
  }
  form {
    border: none;
    padding: 0;
  }
  .blocker-message {
    padding-top: 20px;
    font-size: 1.7rem;
  }
}

.requestContext {
  text-align: center;
  font-size: 1.3rem;
  .missingInfo {
    color: red;
  }
  font-weight: normal;
}

.verificationTypingRequestDate {
  font-size: 1.4rem !important;
  width: 300px !important;
}

.receivingInstitutionDropdown {
  padding-top: 2%;
  .react-select__control,
  h3 {
    margin: 0px;
  }
}

.extendedTypingRequestRadios {
  padding: 15px;
  input[type='radio'] {
    margin: 3px 6px 3px 3px;
    vertical-align: middle;
  }
  label {
    display: inline;
  }
}

.bloodRequirementsTable {
  border: 1px solid $grey;
  padding: 10px;

  .bloodRequirementsError {
    color: $red;
  }

  .bloodRequirementsRow {
    display: flex;

    .bloodRequirementsCell {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &.bloodRequirementsHeader {
      text-align: center;
      background-color: $light-grey;
    }
  }
}
