.btn--info {
  width: 24px;
  height: 24px;
  min-height: 0;
  padding: 0;
  vertical-align: middle;
  background-color: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%23899c1c' viewBox='0 0 24 24' width='24' height='24'><path d='M12,2C6.477,2,2,6.477,2,12s4.477,10,10,10s10-4.477,10-10S17.523,2,12,2z M13,17h-2v-6h2V17z M13,9h-2V7h2V9z'/></svg>");
}

.btn--info:hover {
  background-color: transparent;
}

.btn.btn--info {
  margin: 0 5px;
}

