// Import Anthony Nolan Styleguide
@import '~@an/design-system/src/styles/main';
@import 'virtualized-table-style';
@import 'toast-container-style';
@import './buttons/button-info';

body {
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.group {
  position: static;
}

.content {
  position: relative;
}

.section {
  @include clearfix();
  max-width: 100%;
}

@include mq('mobile') {
  .span_4_of_12 {
    &:nth-child(3n + 1) {
      margin-left: 0;
      clear: both;
    }
  }
}

h2:first-of-type {
  margin-top: 0;
}

button,
.btn {
  border-radius: 0;
}
.btn:focus {
  outline: none;
}

.btn-actions {
  text-align: center;
  .btn {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.btn-group {
  display: flex;
  justify-content: center;
  margin: 10px 0 0;
  .btn {
    margin-bottom: 0;
  }
}

// react-select styles
.react-select__input {
  input {
    height: auto;
  }
}

.react-select__option {
  background-color: white !important;
  color: black !important;
}

.react-select__option:hover {
  background-color: $gray-300 !important;
}

.react-select__control {
  border-radius: 0 !important;
  border: 1px solid $gray-300 !important;
  min-height: 50px !important;
  margin: 8px 0 5px;
  text-align: left;
}

.react-select__value-container {
  font-weight: normal !important;
}

.react-select__menu {
  z-index: 2 !important;
}

.react-select__menu-list {
  font-weight: 400;
}

.react-select__control--is-focused {
  border-color: $green-darker !important;
  box-shadow: none !important;
}

.error-message {
  margin: 0.5em 0;
  text-align: center;
}
aside .error-message,
.po-number .error-message {
  text-align: left;
}

.po-number input[type='text'] + .error-message {
  margin: 0 0 1em;
}

.warning-message {
  display: block;
  margin: -5px 0 1em;
  @include fontSize(16);
  font-style: italic;
  font-weight: 400;
  color: #ffc107;
  text-align: center;
}

header {
  padding: 0;
  margin: 0 0 20px;
  section {
    max-width: 100%;
  }
  h1 {
    @include fontSize(24);
    transform: none;
    top: 0;
    margin: 1.6em 0;
    float: none;
    @include mq('tablet') {
      @include fontSize(32);
      float: right;
    }
  }
  img {
    display: block;
    height: 40px;
    padding: 5px;
  }
}

.nav {
  border-left: 1px solid $gray-300;
  border-right: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
}

.nav-block {
  list-style: none;
  margin: 0;
  text-align: center;
  padding: 0;
  line-height: 40px;
  box-sizing: border-box;
  @include fontSize(12);
  li {
    display: inline-block;
    margin: 0;
    padding: 0;
    a {
      padding: 0 15px;
      display: block;
      line-height: 40px;
    }
    &::before {
      content: unset;
    }
  }
  .active {
    position: relative;
    background-color: $green-darker;
    color: $white;
    text-decoration: none;
    box-sizing: border-box;
    font-weight: bold;
    &::after {
      margin-top: -1px;
      top: 100%;
      position: absolute;
      left: 50%;
      margin-left: -10px;
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: $green-darker transparent transparent transparent;
    }
  }
}

.menu {
  float: left;
  @include fontSize(14);
}

.user-profile {
  float: right;
  border-left: 1px solid $gray-300;
  width: 400px;
}

.indicator-row {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 40px;
  div {
    display: table-cell;
    padding: 0 2px;
    height: auto;
    border-right: none;
  }
  .page-complete,
  .page-completing,
  .page-incomplete {
    background: none;
    span {
      display: block;
      height: 10px;
      margin-bottom: 5px;
    }
  }
  .page-complete span {
    background-color: $gray-200;
  }
  .page-completing {
    font-weight: 700;
    span {
      background-color: $green;
    }
  }
  .page-incomplete span {
    background-color: $gray-300;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 15px;
  padding: 20px;
  text-align: left;
  width: 100%;
}
form table {
  margin-bottom: 30px;
}
tr:nth-child(odd) {
  background-color: $gray-100;
  &.selected {
    background-color: #ffff323a;
  }
}
tr:nth-child(even) {
  background-color: white;
  &.selected {
    background-color: #ffff323a;
  }
}
th {
  font-weight: 700;
}
th,
td {
  padding: 5px;
}
td {
  border: 1px solid $gray-500;
}
.table--no-inner-border {
  border: 1px solid $gray-500;
  > tbody {
    > tr {
      > td {
        border: none;
      }
    }
  }
}

.dot:before {
  content: '';
  display: block;
  float: left;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-top: 3px;
  border-radius: 50%;
}
.dot--red:before {
  background: red;
}
.dot--yellow:before {
  background: yellow;
}
.dot--blue:before {
  background: blue;
}
.dot--none:before {
  background: $gray-200;
}

aside {
  > div {
    border-left: 1px solid $gray-300;
    padding-left: 30px;
  }
  h3:first-of-type {
    margin-top: 0;
  }
  table {
    margin-bottom: 30px;
  }
}

form {
  padding: 30px;
  border: 1px solid $gray-300;
}

form h2 {
  text-align: center;
  border-bottom: 1px solid $gray-300;
  padding-bottom: 10px;
}

label {
  display: block;
  h3 {
    color: $black;
  }
}

.down-arrow {
  position: relative;
  height: 50px;
  margin: 30px 0;
  &:before {
    position: absolute;
    border-style: solid;
    border-width: 2px 2px 0 0;
    border-color: $green;
    content: '';
    display: inline-block;
    width: 50px;
    height: 50px;
    top: -18px;
    left: 50%;
    margin-left: -25px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    vertical-align: middle;
  }
}

.btn--dispatch-boxes {
  position: absolute;
  top: 12px;
  right: 20px;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1;
  .popup {
    position: relative;
    max-width: 500px;
    background: $white;
    padding: 30px;
    border: 1px solid $gray-300;
    text-align: center;
    box-shadow: 0 0 20px $gray-200;
  }
}

.close {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 20px;
  min-height: 0;
  opacity: 0.5;
  position: absolute;
  right: 10px;
  top: 5px;
  width: 20px;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  background-color: #333;
  content: ' ';
  height: 20px;
  left: 10px;
  position: absolute;
  top: 0;
  width: 2px;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

input:disabled,
textarea:disabled,
button:disabled {
  opacity: 0.5;
  cursor: default;
}

.loader {
  margin: 0 auto;
  border-top: 16px solid $green-darker;
}

.loader--btn {
  float: right;
  width: 10px;
  height: 10px;
  border-width: 3px;
  margin: 5px 0 0 10px;
}

.loader--table {
  width: 10px;
  height: 10px;
  border-width: 3px;
  vertical-align: middle;
}
.btn--small .loader--btn {
  border-width: 2px;
  margin: 3px 0 0 10px;
}

.box {
  margin: 0 0 1.5em;
}

.box-create {
  text-align: center;
}

.box-label {
  box-sizing: border-box;
  border: 1px solid $gray-300;
  padding: 20px;
  margin-bottom: 20px;
  &:nth-of-type(2) {
    display: none;
  }
  img {
    display: block;
    width: 250px;
    margin: 10px auto 20px;
  }
}

.panel,
.box-table,
.dot-table,
.results-table {
  position: relative;
  margin-bottom: 2em;
  @include fontSize(14);
  thead tr {
    background: none;
  }
  th,
  td {
    padding: 10px;
  }
  thead h2 {
    text-align: center;
    border-bottom: 1px solid $gray-300;
    padding-bottom: 0.5em;
    margin-bottom: 0;
  }
  tr {
    text-align: left;
  }

  .box-actions {
    width: 100px;
    text-align: center;
    .btn--inline {
      min-width: 100px;
      margin: 5px;
      text-align: center;
      flex-direction: row;
    }
  }
  .box-actions--row {
    width: 300px;
  }
  tfoot td {
    padding: 0;
  }
}

.panel {
  margin-bottom: 2em;
}

.box-table--scroll {
  tbody {
    display: block;
    max-height: 240px;
    overflow-y: scroll;
    width: 100%;
  }
  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  tr th:first-child,
  tr td:first-child {
    width: 150px;
  }
  // Make scrollbar always visible in webkit browsers
  // https://stackoverflow.com/questions/7492062/css-overflow-scroll-always-show-vertical-scroll-bar
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.dispatch-table {
  text-align: vertical;
  width: 100%;
  border: 1px solid $gray-300;
  border-spacing: 0;
  padding: 0px;
}

.box-search {
  h2 {
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  label {
    text-align: center;
  }
  table {
    border-top: 0;
  }
}

.create-modal-button {
  text-align: center;
}

// Hide/modify elements for printing box labels
// All App elements are hidden by default (overlays, including the Box label overlay are rendered in a separate React tree - see https://github.com/tajo/react-portal)
@media print {
  .App,
  .no-print,
  h2,
  .btn {
    display: none;
  }
  .box-create {
    border: none;
    h2 {
      display: none;
    }
  }
  .box-label-container {
    position: relative;
    height: 95vh;
  }
  .box-label {
    position: absolute;
    top: 4%;
    height: 40%;
    width: 100%;
    margin: 0;
    border-color: $gray-200;
    &:nth-of-type(2) {
      display: block;
      top: auto;
      bottom: 0;
    }
    img {
      margin: 30px auto 40px;
    }
  }
  .overlay {
    position: static;
    .popup {
      position: static;
      max-width: none;
      border: none;
      padding: 0;
      width: 100%;
    }
  }
}

// Date picker
.date-selector {
  margin-top: 1em;
  text-align: center;
  label {
    margin: 0;
  }
}
.DateRangePicker {
  margin: 0 0 1em;
}

.DateInput {
  padding: 5px;
}
.DateInput_fang {
  margin-top: 1px;
}
input.DateInput_input {
  font-weight: normal;
  padding: 12px;
  text-align: center;
}
.DayPicker {
  td {
    padding: 0;
    text-align: center;
  }
  button {
    display: block;
    flex-direction: row;
    text-align: center;
    margin: 0;
    min-height: 0;
  }
}
.CalendarMonth_caption {
  padding-bottom: 45px;
}

.CalendarDay__highlighted_calendar {
  background: $green-darker;
  color: $white;
  &:hover {
    background: $green;
    border: $green-darker;
    color: $green-darker;
  }
  &:active {
    background: $green-darker;
    color: $green;
  }
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background-color: lighten($green, 60%);
  border-color: lighten($green, 45%);
  color: $gray-300;
}

.CalendarDay__selected_span {
  background: $green;
  border: $green-darker;
  color: $white;
  &:active,
  &:hover {
    background: lighten($green, 30%);
    border: $green-darker;
  }
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: $green;
  border: $green-darker;
  color: $white;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  border-right: 33px solid $green;
  &:hover {
    border-right: 33px solid lighten($green, 15%);
  }
}

.DateRangePickerInput_arrow {
  margin-top: -6px;
}

.DateRangePickerInput__showClearDates {
  padding-right: 0;
  border: $green-darker;
}

.DateRangePickerInput_clearDates {
  position: absolute;
  right: -25px;
  top: 48%;
  width: 20px;
  height: 20px;
  opacity: 0.5;
  cursor: pointer;
  margin: 0;
  padding: 0;
  min-height: 0;
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: 0;
}

// PO Number field
.po-number label {
  text-align: center;
}
.po-input-wrapper {
  position: relative;
  &::before {
    content: 'LABGEN';
    float: left;
    left: 0;
    width: 80px;
    border: 1px solid $gray-300;
    padding: 12px;
    margin: 0.5em 0 0.75em;
    background: $gray-300;
  }
  input {
    width: calc(100% - 106px);
  }
}

// Improve checkbox/radio styling
// @TODO add back to Styleguide
.control {
  display: inline-block;
  padding-left: 0;
  span {
    display: inline-block;
    vertical-align: middle;
  }
}
.control__indicator {
  position: relative;
  margin-top: 0;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}
.control--checkbox {
  width: 100%;
  vertical-align: middle;
  margin: 0;
  padding: 5px;
  span {
    margin-left: 10px;
  }
  @include mq('desktop', max) {
    margin-right: 0;
    span {
      width: 80%;
    }
  }
}
// End checkbox/radio styling

// Tabs
.nav-tabs {
  list-style: none;
  margin: 20px 0 -1px;
  padding: 0;
  position: relative;
  z-index: 1;
  li {
    display: inline-block;
    margin: 0;
    padding: 0;
    &::before {
      content: none;
    }
    a {
      display: block;
      padding: 10px 15px;
      margin-right: 5px;
      text-decoration: none;
      background: $gray-200;
      border: 1px solid $gray-300;
      &:hover {
        background: $white;
      }
      &.active {
        background: $white;
        border-bottom-color: transparent;
        font-weight: 700;
      }
    }
  }
}

.form-radio-wrapper {
  margin-right: 15px;
}

// card headers
.cardHeader {
  background-color: $green-darker;
  color: white;
  padding: 10px;
  font-family: $gotham;
}

.cardHeader--disabled {
  background-color: $gray-300;
  color: white;
  padding: 20px;
}
.summaryCard {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  padding-bottom: 20px;
}

.summaryCardColumn {
  flex: 50%;
}
.summaryCardTable {
  table,
  tr,
  td {
    vertical-align: top;
    min-width: 100px;
    border-style: none;
    background-color: transparent;
    padding: 0px 10px 0px 0px;
    .numberCell {
      width: 10%;
      text-align: center;
    }
    .searchTypesCell {
      white-space: nowrap;
    }
  }
  td:nth-child(1) {
    white-space: nowrap;
  }
  .btn {
    margin-bottom: 0px;
  }
}
.patientInfoResultsCard {
  padding: 20px;
  border: 1px solid #ddd;
  margin-top: 10px;
}
.newSearchRequestPopUp {
  label {
    font-weight: 400 !important;
    input {
      margin-right: 10px;
    }
  }
}

.newIDMRequestPopUp {
  float: centre;
  td {
    border: none;
  }
}

.chooseRequestPopUp {
  padding-top: 20px;
  h2 {
    text-align: center;
  }
  text-align: center;
}

.buttonGroup {
  button {
    margin-bottom: 20px;
    width: 200px;
    justify-content: center;
  }
  justify-items: center;
  display: inline-grid;
  margin: 20px 0;
}

.popUpForm {
  border: none;
}

.fieldRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  input {
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.btn--disabled {
  pointer-events: none;
  opacity: 0.35;
}

.panel,
.results-table--border {
  border: 1px solid $gray-300;
  border-spacing: 0;
  padding: 20px;
}

.border-bottom-solid {
  margin-top: 10px !important;
}

.control__indicator--table {
  width: 20px;
  height: 20px;
  margin: 0;
  top: 0;
}

.btn--table {
  min-height: 36px;
  text-transform: none;
  text-align: center;
  justify-content: center;
  font-weight: bold;
  width: 100%;
  margin-right: 10px !important;
}

.control--recommendations {
  display: inline-flex;
  .control__indicator {
    flex-shrink: 0;
  }
}

.notifications-tc {
  margin-left: -180px !important;
  width: auto !important;
}

.none-text {
  color: grey;
}

.__react_component_tooltip.tooltip {
  &.show {
    opacity: 1;
  }
  border-radius: 0;
  background-color: #d2d9ac;
  color: $black;
  pointer-events: auto;
  max-width: 350px;
  word-wrap: break-word;
  &:hover {
    visibility: visible;
    opacity: 1;
  }
  &.place-right:after {
    border-right-color: #d2d9ac;
  }
  &.place-top:after {
    border-top-color: #d2d9ac;
  }
  &.place-bottom:after {
    border-bottom-color: #d2d9ac;
  }
  &.place-left:after {
    border-left-color: #d2d9ac;
  }
}

// TODO: WEB-264: move additional button styling back to design system

.btn {
  font-family: $gotham;
  margin: 1rem 0;
  padding: 0 1rem;
  @include fontSize(13);
}

.btn--table-btn {
  height: 30px;
  margin: 0;
  justify-content: center;
}

.btn--uk-search-actions {
  height: 30px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.btn--arrow-left {
  &::before {
    margin: -3px 0 0;
  }
}

.btn--inline {
  display: inline-flex;
  margin-right: 1rem;
}

.btn--center {
  text-align: center;
}

.disabled-action {
  display: flex;
  box-sizing: border-box;
  position: relative;
  justify-content: center;
  border: none;
  font-family: 'Gotham SSm A', 'Gotham SSm B', Arial, Helvetica, sans-serif;
  color: #fff;
  background: darkgrey;
  flex-direction: column;
  min-height: 44px;
  margin: 1rem 0;
  padding: 10px 15px 10px 15px;
  font-size: 1.3rem;
}

input[type='date'],
input[type='datetime-local'],
input[type='text'],
input[type='number'],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  border-radius: 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-size: 1.6rem;
  margin: 8px 0 5px;
  padding: 12px 15px;
  width: 100%;
}

input[type='date'] {
  padding: 11px 15px;
}

input[type='datetime-local']:disabled {
  opacity: 1;
}

input[type='checkbox'] {
  background-color: white;
  cursor: pointer;
  vertical-align: middle;
  &:checked {
    background-color: white;
    &:after {
      border: solid $green-darker;
      border-width: 0 3px 3px 0;
    }
  }
}

input[type='radio'] {
  cursor: pointer;
  &:checked {
    background-color: white;
    &:after {
      background: $green-darker;
    }
  }
}

input[type='checkbox'][disabled] {
  cursor: default;
}

.expand-arrow {
  background-color: $gray-200;
  border: none;
  cursor: pointer;
  display: flex;
  height: 18px;
  width: 34px;
  margin: 0 auto;
  min-height: 0px;
  outline: none;
  padding: 0px;
  text-transform: capitalize;
  align-items: center;
}

.recommendations-list {
  li {
    align-items: center;
    cursor: pointer;
    display: flex;
    list-style: none;
    margin: 0 0 1.5rem;
    padding: 0;
    input {
      cursor: pointer;
    }
    &::before {
      content: none;
    }
  }
}

.react-tiny-popover-container--center {
  transform: translate(-50%, -50%) !important;
}

.react-datepicker {
  font-size: 1em !important;
}
.react-datepicker-popper {
  z-index: 2 !important;
}
.react-datepicker__year-select {
  width: 125% !important;
  background-size: 15px 10px !important;
}
.react-datepicker__year-dropdown-container--select {
  margin-right: 20px !important;
}
.react-datepicker__month-select {
  padding-right: 30px !important;
  background-size: 15px 10px !important;
}
.react-datepicker__header {
  padding-top: 0.8em !important;
}
.react-datepicker__month {
  margin: 0.4em 1em !important;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em !important;
  line-height: 1.9em !important;
  margin: 0.166em !important;
}
.react-datepicker__current-month {
  font-size: 1em !important;
}
.react-datepicker__navigation {
  top: 1em !important;
  line-height: 1.7em !important;
  border: 0.45em solid transparent !important;
}
.react-datepicker__navigation--previous {
  left: 1em !important;
}
.react-datepicker__navigation--next {
  right: 1em !important;
}

// This style is used for the donor HLA table in the Send Results popover and TC Test Results table
.table {
  border-collapse: collapse;
  font-size: 1.4rem;
  width: 100%;
  tr.active-row {
    background-color: #ffff324c;
  }
  .btn {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.expanded-row {
  background-color: #ffff323a;
}
.table-header {
  color: #fff;
  text-transform: uppercase;
  tr {
    background-color: #575749 !important;
    max-width: 90px;
    padding-left: 16px;
    text-align: start;
  }
}
.table--left-header {
  th {
    border: 1px solid $gray-500;
  }
}
.centered-table-data {
  text-align: center !important;
  .chevron {
    white-space: nowrap;
    text-align: left;
  }
}
.table-wrapper {
  max-height: 60vh;
  margin-top: 20px;
  overflow: auto;
  // Required to prevent horizontal scrollbar when using a table with border-collapse
  padding-right: 1px;
  // Required for https://bugs.chromium.org/p/chromium/issues/detail?id=1033712
  will-change: transform;
}
.table--sticky-header {
  tr {
    position: sticky;
    top: 0;
    z-index: 3;
  }
}

// The Search user would like to minimise the screen and work with two browsers, one next to the other.
// When minimised, the page will show a horizontal scroll bar to help the Search user to scroll the table on the right.
.fixed-width {
  min-width: 1470px;
}

.alert {
  position: relative;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  margin: auto;
  max-width: fit-content;
}

.alert--danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert--warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alert--success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.info-tooltip__circle {
  background: $green-darker;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.info-tooltip__circle > div {
  text-align: center;
}
