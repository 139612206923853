.sample-information-table {
  th {
    height: 50px;
  }
  td {
    height: 80px;
  }
  &__informationType {
    width: 190px;
  }
  &__remark {
    width: 300px;
  }
  &__receivedDate {
    width: 30px;
  }
}
