.hla-section-container {
  padding: 16px;
  width: 100%;
}
.hla-title {
  min-width: 120px;
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 4px;
}
.hla-input-section {
  max-width: 88%;
  box-sizing: border-box;
}
.patient-genetics {
  align-items: center;
  display: flex;
}
.donor {
  display: flex;
}
.hla-section-even {
  background: #899c1c30;
}
.hla-section-odd {
  background: #ffffff;
}

.selection-helper-text {
  overflow: auto;
  word-break: break-all;
}

@media only screen and (max-width: 1200px) {
  .donor {
    display: block;
  }
  .patient-genetics  {
    display: block;
  }
  .hla-section-container {
    display: block;
  }
  .helper-text {
    margin-left: 0 !important;
  }
}

@media only screen and (min-width: 1300px) {
  .hla-section-container {
    display: flex;
  }
} 
