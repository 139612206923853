@import '~@an/design-system/src/styles/partials/_variables.scss';

.send-results-popup-header {
  border-bottom: 1px solid $gray-300;
  text-align: center;
  line-height: 24px;
  padding-bottom: 20px;
}
.hla-table-container {
  margin: 20px 0 0 20px;
}
.columns-container {
  grid-template-columns: 1fr 3fr;
  display: grid;
  padding: 0 !important;
  border: unset !important;
}
.details-wrapper {
  border: 1px solid $gray-300;
  margin: 20px 0px;
  padding: 10px 10px 20px;
}
.comments-wrapper {
  margin: 20px 0px;
  textarea {
    resize: none;
  }
}
.btn-container {
  button {
    margin-bottom: 20px;
    width: 100%;
    justify-content: center;
  }
  .hide {
    visibility: hidden;
  }
}

.checkbox-label {
  display: flex;
  input[type='checkbox'] {
    margin: 0px 5px 0px 0px;
  }
  p {
    margin: 12px 10px;
    font-size: 14px;
  }
}

.modal-container {
  .ReactModal__Overlay {
    height: 300px;
    text-align: center;
    transform: translate(50%, 50%);
    width: 50%;
    z-index: 99;
  }
  .buttons-container {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
    width: auto;
    button {
      margin-left: 15px;
    }
  }
}

.radiobuttons-container {
  border: 1px solid $gray-300;
  margin: 20px 0px;
  padding: 10px 10px 20px;
  .disabled {
    opacity: 50%;
  }
  input[type='radio']:disabled {
    cursor: default;
  }
  button {
    width: 100%;
    text-align: center;
    display: inline-block;
  }
}

.radiobutton-wrapper {
  display: flex;
  align-items: center;
  h6 {
    margin-left: 5px;
    padding-top: 5px;
  }
}