$color--lightGrey: #f9f9f9;
$color--grey: #ddd;
$color--fadedYellow: #ffff323a;
$color--yellow: #ffff324c;
$color--fadedRed: #ffe1e1;
$color--orange: #f3caa7;

.odd {
  background-color: $gray-100;
  border-top: 1px solid $gray-500;
  border-left: 1px solid $gray-500;
  &.action-required {
    background-color: $color--orange;
  }
  &.selected {
    background-color: $color--yellow;
    font-weight: bold;
    &.readOnly {
      background-color: rgba(255, 255, 50, 0.15) !important;
      font-weight: unset;
    }
    .btn {
      font-weight: normal;
    }
  }
  &.untrustworthy {
    background-color: $color--fadedRed !important;
  }
}

.even {
  background-color: white;
  border-top: 1px solid $gray-500;
  border-left: 1px solid $gray-500;
  &.action-required {
    background-color: $color--orange;
  }
  &.selected {
    background-color: $color--fadedYellow;
    font-weight: bold;
    &.readOnly {
      background-color: rgba(255, 255, 50, 0.11) !important;
      font-weight: unset;
    }
    .btn {
      font-weight: normal;
    }
  }
  &.untrustworthy {
    background-color: $color--fadedRed !important;
  }
}

.cell,
.headerCell,
.leftCell {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.headerCell,
.leftCell {
  font-weight: bold;
}

.selectedDonor {
  background-color: green;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ReactVirtualized__Table__headerRow {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #575749 !important;
  border-right: 1px solid $gray-300 !important;
  padding-right: 0px !important;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: white;
  height: 100%;
  &:last-child {
    border-right: none;
    padding: 0 !important;
  }
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__row {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
}

.ReactVirtualized__Table__headerColumn {
  line-height: 1.2;
  &:last-child {
    padding: 0 !important;
  }
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 0;
  min-width: 0px;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 0;
}

.ReactVirtualized__Grid:focus {
  outline: none;
}

.ReactVirtualized__Grid {
  border-bottom: none !important;
}

.ReactVirtualized__Table,
.ReactVirtualized__Grid {
  .control--checkbox {
    padding: 0;
    input,
    .control__indicator {
      top: 7px;
    }
  }
}

.ReactVirtualized__Table__rowColumn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid $gray-500 !important;
  align-items: center;
  text-overflow: ellipsis;
  word-wrap: break-word;
  &:last-child {
    border-right: none;
  }
  &.column-wrap {
    white-space: normal;
  }
  .control--checkbox {
    padding: 0;
  }
}

.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: flex;
  align-items: center;
}
.ReactVirtualized__Table__sortableHeaderIcon {
  flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
}
