.message-denial {
  &__patientId {
    max-width: 10ch;
  }
  &__remarkCell {
    max-width: 22vw;
  }
  &__emdisRegistryCell {
    max-width: 13vw;
  }
  &__receivedDate {
    max-width: 12ch;
  }
  &__gridCell {
    width: 25ch;
  }
}
