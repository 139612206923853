.test-requests {
  &__patientIdCell {
    max-width: 10ch;
  }
  &__gridCell {
    width: 25ch;
  }
  &__dateCell {
    max-width: 12ch;
  }
}
