@import '~@an/design-system/src/styles/partials/_variables.scss';

$color--fadedYellow: #ffff323a;

.link-summary {
  background-color: #fff;
}

.link-summary__table,
.subject-result__table {
  background-color: $white;
  th,
  td {
    border: none;
  }
}
.link-summary__table {
  th,
  td {
    width: 50%;
  }
}

.main-link {
  background-color: $color--fadedYellow;
}

.confirm-popup,
.info-popup {
  width: 300px;
  padding: 20px;
  margin-top: -15px;
  &::before {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    border-color: rgb(255, 255, 255) transparent transparent;
    border-style: solid;
    bottom: -5px;
    margin-left: -5px;
    border-width: 5px 5px 0px;
    left: 50%;
  }
}
.confirm-popup {
  text-align: center;
}

.link-summary {
  .btn-group {
    .btn {
      text-transform: capitalize;
    }
  }
}