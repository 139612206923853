$color--red: #ec3d3d;
$color--light-red: #f4e9e9;
$color--faded-red: rgba(236, 61, 61, 0.9);
$color--light-green: #f0f5ea;
$color--faded-green: rgba(94, 164, 0, 0.9);
$color--green: #5ea400;

.hla-nmdp-display {
  word-break: break-word;
  cursor: pointer;

  .toast-content {
    width: 96vw;
    font-size: 16px;
    margin: 10px 0px 0px;
    display: block;
    opacity: 1;
    background-color: rgb(232, 240, 244);
    border-top: 2px solid rgb(137, 156, 28);
    box-shadow: rgba(54, 156, 199, 0.9) 0px 0px 1px;
    color: rgb(65, 85, 93);
    height: auto;
  }
}

.action-notification {
  text-align: center;
  font-size: 15px;
  margin-left: 0 !important;
  .Toastify__toast-body {
    white-space: pre-line;
  }
  .Toastify__close-button {
    margin: 0px;
  }
  .Toastify__toast--success {
    margin: 0px;
    min-height: 30px;
    border-top: 2px solid $color--green;
    color: #4b583a;
    background-color: $color--light-green;
    border-radius: 0;
  }
  .Toastify__toast--error {
    margin: 0px;
    min-height: 30px;
    border-top: 2px solid $color--red;
    color: #412f2f;
    background-color: $color--light-red;
    border-radius: 0;
  }
}
.Toastify {
  .Toastify__toast-container {
    width: auto;
  }
}
