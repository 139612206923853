.subject-details-form {
  margin-top: 10px;

  &-column {
    display: grid;
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    &-3 {
      grid-template-columns: repeat(3, 1fr);
    }
    &-4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.no-results-message {
  background-color: #899c1c;
  color: #fff;
  display: block;
  margin: 50px auto;
  max-width: fit-content;
  padding: 1em 2em;
  position: relative;
  text-align: center;
}
.table-hla {
  min-width: 1200px;
  table-layout: fixed;
  &__wrapper {
    width: 100%;
    overflow: auto;
  }
  tr {
    height: 50px;
  }
}
