.text-messages-table {
  th {
    height: 50px;
  }
  td {
    height: 80px;
  }
  &__message {
    width: 300px
  }
  &__date {
    width: 50px;
  }
}
