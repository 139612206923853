.text-message-table {
  th {
    height: 50px;
  }
  td {
    height: 80px;
  }
  &__donorId {
    width: 200px;
  }
  &__date {
    width: 140px;
  }
  &__emdisRegistry {
    width: 220px;
  }
}
