.external-investigations-table {
  .ReactVirtualized__Table__rowColumn,
  .ReactVirtualized__Table__headerColumn {
    padding: 3px;
  }

  .urgent-column-header {
    width: 100%;
  }

  .btn--test-result {
    width: 120px;
    line-height: normal;
  }

  .disabled-action.btn--test-result {
    min-height: 30px;
    padding: 0;
    margin: 0;
  }
}

.investigation-details {
  &.idm {
    width: 200px;
  }
  &.extended-typing {
    width: 250px;
  }
  &.vt {
    width: 200px;
  }
  .investigation-details-container {
    display: flex;
    flex-wrap: wrap;
  }
  .investigation-details-item {
    width: 50%;
    text-align: left;
  }
  .remark {
    padding-top: 3px;
    word-break: break-word;
  }
}
