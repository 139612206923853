.savedSetsTable {
  align-items: unset !important;
  text-align: left;
  white-space: initial;
  padding-left: 30px;
}

.filterSummaryHeader {
  padding-left: 30px;
}

.internationalReportRegistries {
  border: none;
  padding: 0;
  border-collapse: collapse;
  th {
    font-size: 1.6rem;
    font-weight: normal;
    padding: 0 10px;
    text-align: center;
    &:first-child {
      padding: 0;
    }
    h2 {
      border: none;
      margin-top: 10px;
      text-align: left;
    }
  }
  td {
    font-size: 1.6rem;
    &:first-of-type {
      min-width: 300px;
    }
    input[type='checkbox'] {
      left: 50%;
      margin-left: -12px;
      top: unset;
    }
    input[type='text'][disabled] {
      background: none;
      border: none;
      height: auto;
      margin: 0;
      opacity: 1;
      padding: 0;
    }
  }
}

.selectedDonorSetsTable {
  table {
    border: unset;
    padding: 0px;
  }
  thead {
    font-weight: 600;
  }
  .searchTypeColumn {
    width: 250px;
  }
  .filterSummaryColumn {
    width: 430px;
  }
  .notesColumn {
    width: 390px;
  }
  .donorsColumn {
    text-align: center;
    width: 80px;
  }
  tbody {
    overflow: auto;
    height: auto;
  }
}
