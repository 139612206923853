.tc-container {
  width: 100%;
}

.test-request-filters {
  display: flex;
  align-items: flex-end;
  label {
    margin-right: 10px;
  }
  label[for='transplantCentre'] {
    min-width: 300px;
  }
  .btn {
    height: 50px;
    margin-bottom: 5px;
  }
}

.dialogue-container {
  text-align: center;
  .btn-actions {
    display: inline-flex;
  }
}

.expanded {
  background-color: #ffff323a !important;
}
