@import '~@an/design-system/src/styles/partials/_variables.scss';

$grid-column-size-thirds: 33%;
$grid-column-size-quarters: 25%;

%hla-summary-popup-centered-shared {
  display: flex;
  align-items: center;
  flex-direction: column;
}

%hla-summary-popup-table-cell-shared {
  @extend %hla-summary-popup-centered-shared;
  font-size: 15px;
  line-height: 20px;
  height: 55px;
  padding: 1px 0;
  justify-content: space-around;
}

.hla-summary-popup-table-cell {
  @extend %hla-summary-popup-table-cell-shared;
}

.hla-summary-popup-table {
  display: grid;
  border: $gray-300;
  margin-top: 10px;
  margin-bottom: 12px;
  &.w-33 {
    grid-template-columns: $grid-column-size-thirds $grid-column-size-thirds $grid-column-size-thirds;
    padding: 13px;
  }
  &.w-25 {
    grid-template-columns: $grid-column-size-quarters $grid-column-size-quarters $grid-column-size-quarters $grid-column-size-quarters;
  }
}

.hla-summary-popup-remark-field {
  margin: 5px;
  width: 100%;
  padding: 10px;
  background: #f9f9f9;
  border: $gray-300;
  white-space: pre-wrap;
}

.hla-summary-popup-remark-header {
  font-size: 20px;
}

.hla-summary-popup-header {
  border-bottom: $gray-300;
  text-align: center;
  padding-bottom: 20px;
}

.hla-summary-popup-table-cell {
  @extend %hla-summary-popup-table-cell-shared;
  font-weight: lighter;
}

.hla-summary-popup-table-header-cell {
  @extend %hla-summary-popup-table-cell-shared;
  font-weight: bold;
}

.hla-summary-popup-remark-wrapper {
  @extend %hla-summary-popup-centered-shared;
  width: 100%;
  grid-column: 1 / 4;
  justify-content: space-around;
  padding: 13px;
}

.hla-summary-popup-odd {
  background-color: #eeede6;
}

.hla-summary-popup-even {
  background-color: white;
}

.hla-summary-popup-buttons-wrapper {
  text-align: center;
}

.hla-summary-popup-button {
  padding: 20px 45px;
}

.disabled-action.btn--test-result {
  display: inline-flex;
  margin-right: 1rem;
  min-height: 44px;
  text-align: center;
  width: 174px;
}

.hla-summary-popup-button-wrapper{
  display:inline-block;
  vertical-align: top;
  min-height: 44px;
  &.btn-download {
    width: 200px;
  }
  &.btn-close {
    width: 60px;
  }
}
