.input-button-container {
  display: flex;
}

input[type='datetime-local'] {
  width: 32%;
}

.copy {
  margin: 8px 0 5px;
  height: 52px;
}
